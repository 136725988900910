import '../styles/faq.scss';

import React from 'react';

import Layout from '../components/layout';
import SEO from '../components/seo';
import DownArrow from '../images/DownArrow.jpeg';
import UpArrow from '../images/UpArrow.jpeg';

function FaqPage() {

  const onClick_section = ( event, index ) => {
    console.log( 'Event target name >>>>>>>> onClick_section ' + event.target.name );
    if ( !showSections[index] ) {
      setShowSections( { ...showSections, [index]: true } );
    }
    else {
      setShowSections( { ...showSections, [index]: false } );
    }
  };

  const onClick = ( event, index ) => {
    console.log( 'Event target name >>>>>>>> onClick ' + event.target.name, showResults[index] );
    if ( !showResults[index] ) {
      setShowResults( { ...showResults, [index]: true } );
    }
    else {
      setShowResults( { ...showResults, [index]: false } );
    }
  };

  const [ showSections, setShowSections ] = React.useState( { 1: true } );
  const [ showResults, setShowResults ] = React.useState( {} );

  return (
    <Layout header={'menu'}>
      <SEO
        title="Get answers related to Life &amp; Home Insurance Queries "
        description="Our FAQ section will help you get answers to the most frequently asked questions, related to Life and Home Insurance. Visit now!"
        canonicalUrl='https://www.getmybubble.io/faq'
        robots={ { 1:'index', 2: 'follow' } }
      />

      <div id="faq" className="wrapper">
        <div className="flexColumn carrierCommonPage">
          <div className="company-info-container container-fluid">
            <div className="row">
              <div className="col-lg-12" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                <div>
                  <h1 className="carrier-plan-heading section70" style={{ lineHeight: '1.55', textAlign: 'center' }}>
                                    Frequently Asked Questions
                  </h1>
                </div>
              </div>
            </div>
          </div>

          <div className="container faq-margin-bottom common-container-space">
            <div className="row">
              <div className="col-lg-12">
                {/*About Home Insurance*/}

                <div className="row">
                  <div className="col-lg-12">
                    <div className="faqFlex bg-white-border">
                      <div className="faqLeftContainer faq-left-container">
                        <div className="col-sm-12" onClick={( e ) => onClick_section( e, 1 )}>
                          <div className="align-faq-que position-relative">
                            <div className="faq-section-headings">
                                                                Beneficiaries
                            </div>

                            <img
                              src={showSections[1] ? UpArrow : DownArrow}
                              className="faq-dd m-0" name="1" alt="Bubble Life Insurance"/></div>
                        </div>

                        <div className="col-12 both-space-padding">
                          <div className="row">
                            {showSections[1] && <>
                              <div className="col-lg-6 col-12">
                                <div className="faqSubContainer">
                                  <div className="faqFlex faq-container-align">
                                    <div className="faqLeftContainer row">
                                      <div className="col-sm-12 col-12" onClick={( e ) => onClick( e, 3 )}>
                                        <div className="align-faq-que-box position-relative">
                                          <div className="carrier-faq carrier-faq-width"> Can I have more than one
                                                                                                beneficiary? </div><img src={showResults[3] ? UpArrow : DownArrow} className="faq-dd faq-dd-width m-0"
                                            name="14" alt="Bubble Life Insurance" />
                                        </div>

                                        <p className="pt-lg-0 pt-2 carrier-faq faq-para-text">{showResults[3] && `Yes, you can have more
                                                                                            than one beneficiary and you can add them at a later time as well.`}</p>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>

                              <div className="col-lg-6 col-12">
                                <div className="faqSubContainer">
                                  <div className="faqFlex faq-container-align">
                                    <div className="faqLeftContainer row">
                                      <div className="col-sm-12 col-12" onClick={( e ) => onClick( e, 4 )}>
                                        <div className="align-faq-que-box position-relative">
                                          <div className="carrier-faq carrier-faq-width"> Do I need a secondary
                                                                                                beneficiary? </div><img src={showResults[4] ? UpArrow : DownArrow} className="faq-dd faq-dd-width m-0"
                                            name="33" alt="Bubble Life Insurance" />
                                        </div>

                                        <p className="pt-lg-0 pt-2 carrier-faq faq-para-text">{showResults[4] && `The secondary beneficiary
                                                                                            would be someone who accepts the benefit if the primary beneficiary
                                                                                            isn't able to or willing to accept it. If, for any reason, you believe
                                                                                            that your primary beneficiary would or could not accept the benefit, you
                                                                                            could opt to have a secondary beneficiary.`}</p>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>

                              <div className="col-lg-6 col-12">
                                <div className="faqSubContainer">
                                  <div className="faqFlex faq-container-align">
                                    <div className="faqLeftContainer row">
                                      <div className="col-sm-12 col-12" onClick={( e ) => onClick( e, 5 )}>
                                        <div className="align-faq-que-box position-relative">
                                          <div className="carrier-faq carrier-faq-width"> What if I don't have anyone
                                                                                                to nominate as a beneficiary?What if my beneficiary is a minor?
                                          </div><img src={showResults[5] ? UpArrow : DownArrow} className="faq-dd faq-dd-width m-0" name="48"
                                            alt="Bubble Life Insurance" />
                                        </div>

                                        <p className="pt-lg-0 pt-2 carrier-faq faq-para-text">{showResults[5] && `In that case, you can
                                                                                            nominate a charitable or religious organization as a beneficiary. If you
                                                                                            aren't sure of how to go about choosing the right entity, Bubble's
                                                                                            specialist will help you through it.`}</p>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>

                              <div className="col-lg-6 col-12">
                                <div className="faqSubContainer">
                                  <div className="faqFlex faq-container-align">
                                    <div className="faqLeftContainer row">
                                      <div className="col-sm-12 col-12" onClick={( e ) => onClick( e, 6 )}>
                                        <div className="align-faq-que-box position-relative">
                                          <div className="carrier-faq carrier-faq-width"> What if my beneficiary is a
                                                                                                minor? </div><img src={showResults[6] ? UpArrow : DownArrow} className="faq-dd faq-dd-width m-0" name="49"
                                            alt="Bubble Life Insurance" />
                                        </div>

                                        <p className="pt-lg-0 pt-2 carrier-faq faq-para-text">{showResults[6] && `If the beneficiary is a
                                                                                            minor, the two options you have are to nominate a court appointed
                                                                                            financial guardian or to create a trust in their name. And once the
                                                                                            minor is 18 years old, they get the full amount of the benefit without
                                                                                            any restrictions.`}</p>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </>}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                {/*End of Home Insurance */}
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
}

export default FaqPage;
